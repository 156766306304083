import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';

const SocialLink = ({ icon, href, alt }) => (
  <a className="SocialLink" href={href} alt={alt}>
    <FontAwesomeIcon color="#1D6A6D" size="lg" transform="grow-3" icon={icon} />
  </a>
);

const Footer = () => (
  <div className="Footer">
    <SocialLink
      href="mailto:mandymak.me@gmail.com"
      alt="Email me"
      icon={faEnvelope}
    />
    <SocialLink
      href="https://linkedin.com/in/makmandy"
      alt="LinkedIn"
      icon={faLinkedin}
    />
    <SocialLink
      href="https://twitter.com/_mandymak"
      alt="Twitter"
      icon={faTwitter}
    />
    {/* <SocialLink
      href="https://instagram.com/mandymakdesign"
      alt="Instagram"
      icon={faInstagram}
    /> */}
    {/* <SocialLink href="https://mandymak.com" alt="Shopping page" icon={faShoppingBag} /> */}
  </div>
);

export default Footer;
