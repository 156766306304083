import React, { useEffect } from 'react';
import { Global, css } from '@emotion/core';
import TransitionLink from '../TransitionLink/TransitionLink';
import Footer from '../Footer/Footer';

import Navigation from '../Navigation/Navigation';
import { rhythm } from '../../utils/typography';

const hasWindow = typeof window !== 'undefined';

const Layout = ({ children, classes }) => {
  let header;

  useEffect(() => {
    const pathname = hasWindow ? window.location.pathname : '';
    if (hasWindow && !pathname.includes('404')) {
      document.body.classList.remove('daisy');
    }
  }, [hasWindow]);


  header = (
    <div className="Header">
      <TransitionLink className="Header__link" to="/">
        <h3 className="Header__text">
          Mandy Mak
        </h3>
      </TransitionLink>
      <Navigation />
    </div>
  );

  return (
    <div className={classes}>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          * + * {
            margin-top: 0.5rem;
          }
          html,
          body {
            margin: 0;
            color: #333;
            font-family: Tajawal, -apple-system, BlinkMacSystemFont, 'Segoe UI',
              Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';

            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            + * {
              margin-top: 0.5rem;
            }
          }
          a {
            &:hover {
              &:not(.Header__link) {
              transition: all 0.25s ease;
              color: #1d6a6d;
              background-color: #fff;
              box-shadow:
                inset 0px 0px 0px #1d6a6d,
                2px 2px 0px #1d6a6d;
              }
            }
          }
        `}
      />
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(3 / 4)}`,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <header>{header}</header>
        <main style={{ flex: 1 }}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
