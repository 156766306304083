export const styles = {
  '.NumberCircle': {
    userSelect: 'none',
    background: '#58BCC0',
    borderRadius: '50%',
    color: '#fff',
    font: '14px Tajawal, san-serif',
    lineHeight: '20px',
    height: '22px',
    padding: '3px',
    textAlign: 'center',
    minWidth: '22px',
    marginRight: '4px'
  },
  '.NumberCircle.hide': {
    display: 'none',
  }
};
