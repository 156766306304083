export const styles = {
  '.Header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '3rem',
    userSelect: 'none',
  },
  '.Header__text': {
    fontWeight: '700'
  }
};
