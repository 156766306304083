import React, { useEffect, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { CSSTransition } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const [currentPath, setCurrentPath] = useState(null);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : null
  );
  const [expanded, setExpanded] = useState(windowWidth > 760);
  const toggleExpanded = () => setExpanded(!expanded);
  useEffect(() => {
    window.addEventListener('resize', () => {
      const innerWidth = window.innerWidth;
      setWindowWidth(innerWidth);
      setExpanded(innerWidth > 760);
    });
  }, [typeof window !== 'undefined' ? window : null]);

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  const paths = {
    about: '/about/',
    now: '/now/',
    portfolio: '/portfolio',
    reading: '/reading/',
    contact: '/contact/',
  };

  const navigationClass = (pathname, href) => {
    if (pathname && pathname.startsWith(href)) return 'active';
    return '';
  };

  return (
    <div className="Navigation">
      <div className="Navigation__toggle" onClick={toggleExpanded}>
        <CSSTransition
          in={expanded}
          timeout={300}
          classNames="Navigation__toggle-icon"
        >
        <FontAwesomeIcon
          color="#1D6A6D"
          size="1x"
          transform="grow-2"
          icon={expanded ? faTimes : faHamburger}
          />
          </CSSTransition>
      </div>
      <CSSTransition
        in={expanded}
        timeout={300}
        classNames="Navigation__options"
        unmountOnExit
      >
        <div className="Navigation__options">
          <AniLink
            paintDrip
            hex="#77a6a7"
            to="/now"
            className={`Navigation__NavLink ${navigationClass(
              currentPath,
              paths.now
              )}`}
              activeClassName="active"
              >
            Now
          </AniLink>
          <AniLink
            paintDrip
            hex="#77a6a7"
            to="/reading"
            className={`Navigation__NavLink ${navigationClass(
              currentPath,
              paths.reading
              )}`}
              activeClassName="active"
              >
            Reading
          </AniLink>
        </div>
      </CSSTransition>
    </div>
  );
};
export default Navigation;
