export const styles = {
  '.Tag': {
    userSelect: 'none',
    background: '#f4f4f4',
    borderRadius: '15px',
    color: '#65717c',
    font: '14px Tajawal, san-serif',
    padding: '3px 7px',
    marginLeft: '4px',
  },

  '.Tag.reread': {
    userSelect: 'none',
    background: '#ddf1f2',
    borderRadius: '15px',
    color: '#65717c',
    font: '14px Tajawal, san-serif',
    padding: '3px 7px',
    marginLeft: '4px',
  },
};
