export const styles = {
  '.BookList__header': {
    cursor: 'pointer',
    userSelect: 'none',
  },
  '.BookList__header.Currently': {
    cursor: 'auto',
    userSelect: 'auto',
  },
  '.BookList__list-enter': {
    opacity: 0,
    transform: 'scale(0.9)',
  },
  '.BookList__list-enter-active': {
    opacity: 1,
    transform: 'translateX(0)',
    transition: 'opacity 300ms, transform 300ms',
  },
  '.BookList__list-exit': {
    opacity: 1,
  },
  '.BookList__list-exit-active': {
    opacity: 0,
    transform: 'scale(0.9)',
    transition: 'opacity 300ms, transform 300ms',
  },
  '.BookList__chevron-enter-active': {
    transform: 'rotate(90deg)',
    transition: 'transform 300ms',
  },
  '.BookList__chevron-exit': {
    transform: 'rotate(90deg)'
  },
  '.BookList__chevron-exit-active': {
    transform: 'rotate(0deg)',
    transition: 'transform 300ms',
  },
  '.BookList__chevron-enter-done': {
    transform: 'rotate(90deg)'
  }
}