export const styles = {
  '.BookListItem': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.BookListItem__numbered-line': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  '.BookListItem__date': {
    color: '#73808D',
    fontSize: '14px',
    margin: '0 0 0 0.25rem',
  },
  '.BookListItem__date.numbered': {
    margin: '0 0 0 28px',
  },
};
