export const styles = {
  '.Navigation': {
    marginTop: 0,
    userSelect: 'none',
  },
  '.Navigation__NavLink': {
    marginLeft: '0.75rem',
  },
  '.Navigation__NavLink.active': {
    color: '#1d6a6d',
    fontWeight: '600',
  },
  '@media screen and (max-width: 760px)': {
    '.Navigation__options': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
    },
    '.Navigation__toggle': {
      cursor: 'pointer',
      textAlign: 'right',
    },
  },
  '@media screen and (min-width: 760px)': {
    '.Navigation__toggle': {
      display: 'none',
      cursor: 'pointer',
    },
  },
  '.Navigation__toggle-icon-enter-active': {
    transform: 'rotate(90deg)',
    transition: 'transform 300ms',
  },
  '.Navigation__toggle-icon-exit': {
    transform: 'rotate(90deg)'
  },
  '.Navigation__toggle-icon-exit-active': {
    transform: 'rotate(0deg)',
    transition: 'transform 300ms',
  },
  '.Navigation__toggle-icon-enter-done': {
    transform: 'rotate(90deg)'
  },

  '.Navigation__options-enter': {
    opacity: 0,
    transform: 'scale(0.5)',
  },
  '.Navigation__options-enter-active': {
    opacity: 1,
    transform: 'translateX(0)',
    transition: 'opacity 300ms, transform 300ms',
  },
  '.Navigation__options-exit': {
    opacity: 1,
  },
  '.Navigation__options-exit-active': {
    opacity: 0,
    transform: 'scale(0.5)',
    transition: 'opacity 300ms, transform 300ms',
  },
};
